import { Routes } from "@/@types/api/routes.enum";
import useQueryParams from "@/components/hook/use-params"
import { useModal } from "@/contexts/modal-provider";
import { userSessionContext } from "@/contexts/user-context";
import { fetcher } from "@/lib/fetcher";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "sonner";

export const useOrderModal = () => {
  const { obj } = useQueryParams()
  const [address, setAddress] = useState("");
  const { token } = userSessionContext()
  const { setClose } = useModal()
  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const response = await fetcher(Routes.Order, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(data),
            });
            resolve(response);
          } catch (error) {
            reject(error);
          }
        }, 4000);
      });
    },
    onSuccess() {
      toast.success(`Encomendado com sucesso`)
      setClose()
    },
    onError(e) {
      toast.error(`Informações não enviadas`)
    }
  });
  const handleChangeAddress = (newAddress: any) => {
    setAddress(newAddress);
  };
  const handleSelectAddress = (newAddress: any) => {
    setAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
  };
  const handleSubmit = async () => {
    if (token) {
      mutation.mutate({ prices: [0, 1000000000], ...obj,})
      return;
    }
    toast.warning("Precisa está autenticado para solicitar")
  }
  return {
    handleSubmit,
    handleChangeAddress,
    handleSelectAddress,
    address,
    isLoading: mutation.isPending
  }
}