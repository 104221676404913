import { useJsApiLoader } from "@react-google-maps/api";

const GoogleMapsLoader = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
    libraries: ["core", "maps", "places", "marker"],
    language: "pt-BR",
  });

  return { isLoaded, caches };
};

export default GoogleMapsLoader;