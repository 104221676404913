import { FilterOptionsInfoState } from "./options.types"
import { useOptionFilter } from "./options.modal"
import { CategoriesFilter, PropertyCaracteristicos, PropertyOptions, PropertyType } from "@/constant/filters"
import { Input } from "@/components/ui/input"
import { Slider } from "@/components/ui/slider"
import { Button } from "@/components/ui/button"
import { ListRestart, Loader2, Search, Star } from "lucide-react"
import { Label } from "@/components/ui/label"
import { Combobox } from "@/components/ui/combobox"
import { Checkbox } from "@/components/ui/checkbox"
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"


const QUANTITY = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10']
type Props =  {
  isOrder?: boolean
  isLoading?: boolean
  onSubmit: (params: FilterOptionsInfoState) => void
}
export const OptionsFilterView = ({ onSubmit, isLoading, isOrder }:Props) => {
  const { handleResetFilter, handleSalveFilter, info, setInfo, handleOnChange } = useOptionFilter()
  const handleCheckboxChange = (isChecked: boolean, option: string) => {
    setInfo((prev) => {
      const updatedFeatures = isChecked
        ? [...(prev.feature || []), option]
        : prev.feature?.filter((feature) => feature !== option) || [];
      return { ...prev, feature: updatedFeatures };
    });
  };
  const handleCommandItemClick = (option: string) => {
    const isChecked = !info.feature?.includes(option);
    handleCheckboxChange(isChecked, option);
  };

  return (
    <div className="w-full h-full">
      <div className="h-[500px] pr-3 overflow-y-auto scrollbar-custom">
        <div className="flex gap-4 justify-between w-full bg">
          {
            CategoriesFilter.map((option) => (
              <div className={`border-[1px] rounded-xl text-black px-5 py-1 text-sm ${info.transaction === option ? "border-blue-500 bg-blue-300" : "border-gray-300"} hover:border-blue-300 cursor-pointer`}
                onClick={() => {
                  console.log(option)
                  handleOnChange("transaction", option)
                }}
              >
                {option}
              </div>
            ))
          }
        </div>
        <div className="flex flex-col my-2 gap-2">
          <Label>Tipo de imóvel</Label>
          <Combobox label="Tipo de imóvel" className="w-full">
            <Command>
              <CommandGroup>
                <CommandList>
                  {PropertyType.map((option) => {
                    const isChecked = info.types?.includes(option);
                    return (
                      <CommandItem
                        key={option}
                        className={`flex gap-4 cursor-pointer my-1.5 ${isChecked ? 'bg-blue-400' : ''}`}
                        onClick={() => handleCommandItemClick(option)}
                      >
                        <Checkbox
                          disabled={isLoading}
                          id={option}
                          className={`hover:text-blue-600 hover:border-blue-600 hover:bg-blue-200 ${
                            isChecked ? "text-blue-600 border-blue-600 bg-blue-200" : ""
                          }`}
                          checked={isChecked}
                          onCheckedChange={(isChecked) => {
                            const updatedFeatures = isChecked
                            ? [...(info.types || []), option]
                            : info.types?.filter((feature) => feature !== option) || [];
                            handleOnChange("types", updatedFeatures)
                          }}
                        />
                        <label
                          htmlFor={option}
                          className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {option}
                        </label>
                      </CommandItem>
                    );
                  })}
                </CommandList>
              </CommandGroup>
            </Command>
          </Combobox>
        </div>
        <div>
          <Label>Preço</Label>
             <div className="flex flex-col gap-2">
              <div className=" flex items-center justify-between gap-4 ">
                <Input
                  disabled={isLoading}
                  placeholder="R$10.000.000,00"
                  className="h-10"
                  value={info.prices[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.-]+/g, '')
                    const newPrices = [...info.prices]
                    newPrices[0] = Number.parseFloat(value) || 0
                    setInfo((prev) => ({ ...prev, prices: newPrices }))
                  }}
                />

              <div className="h-[1px] w-[10rem] bg-[#d9d9d9] max-md:sr-only" />
                <Input
                  disabled={isLoading}
                  placeholder="R$10.000.000,00"
                  value={info.prices[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.-]+/g, '')
                    const newPrices = [...info.prices]
                    newPrices[1] = Number.parseFloat(value) || 0
                    setInfo((prev) => ({ ...prev, prices: newPrices }))
                  }}
                />
            </div>
            <Slider
              value={info.prices}
              defaultValue={info.prices}
              onValueChange={(e) => setInfo((prev) => ({ ...prev, prices: e }))}
              step={2}
              max={5000000000}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-2">
          {
            PropertyOptions.map(qty => (
              <div className="flex flex-col gap-1.5 ">
                <Label>{qty.label}</Label>
                <div className="w-full overflow-x-auto h-[50px] scrollbar-custom">
                  <div className="w-max flex gap-2">
                    {QUANTITY.map((value, index) => (
                      <Button 
                        key={index} 
                        variant="icon" 
                        onClick={() => {
                          if(info[qty.name as keyof FilterOptionsInfoState]){
                            handleOnChange(qty.name as any, null)
                            return
                          }
                          handleOnChange(qty.name as any, value)
                        }}
                        className={`hover:text-blue-600 hover:border-blue-600 hover:bg-blue-200 ${info[qty.name as keyof FilterOptionsInfoState] === value ? "text-blue-600 border-blue-600 bg-blue-200" : ""}`}
                      >
                        {value}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="mt-3">
          <Label>Área do imóvel</Label>
          <div className="flex gap-2 justify-between">
            <div>
              <p className="text-[12px]">Area total (m²)</p>
                <Input placeholder="minimo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaTotalMin", Number(e.target.value))}/>
            </div>
            <div>
            <p className="text-sm">Area total (m²)</p>
              <Input placeholder="maximo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaTotalMax", Number(e.target.value))} />
            </div>
          </div>
          <div className="flex gap-2 mt-2 justify-between">
            <div>
              <p className="text-[12px]">Area Útil (m²)</p>
              <Input placeholder="minimo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaUtilMin", Number(e.target.value))} />
            </div>
            <div>
              <p className="text-sm">Area Útil (m²)</p>
              <Input placeholder="maximo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaUtilMax", Number(e.target.value))} />
            </div>
          </div>
        </div>  
        <Accordion type="single" className="w-full">
          {PropertyCaracteristicos.map((value, key) => (
            <AccordionItem value={value.label}>
              <AccordionTrigger>
                {value.label}
              </AccordionTrigger>
              <AccordionContent className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-4 gap-y-4  mt-2 transition-all duration-300 ease-in-out max-h-40 overflow-y-auto">
                {value.values.map((f, index) => (
                  <div key={f} className="col-span-1 flex items-center space-x-2">
                      <Checkbox
                        id={f}
                        disabled={isLoading}
                        className="cursor-pointer"
                        checked={info.feature?.includes(f)}
                        onCheckedChange={(e) => {
                          const isExist = info.feature?.includes(f);
                          if(isExist){
                            handleOnChange("feature", info.feature?.filter(() => e))
                          }else{
                            handleOnChange("feature", [...(info.feature || []), f])
                          }
                        }}
                      />
                      <label
                        htmlFor={f}
                        className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {f}
                      </label>
                    </div>
                  ))}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <div className="flex flex-col gap-4 static">
        <Button
          className="flex gap-4 py-4"
          onClick={() => onSubmit(info)}
          disabled={isLoading}
        >
          {isLoading ? <Loader2 className="animate-spin" /> : <Search size={20} />}
          {!isOrder ? "Buscar" : "Encomendar"}
        </Button>
        <div className="flex flex-wrap items-center justify-between gap-4 max-sm:justify-center">
          <div
            onClick={handleResetFilter}
            className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
          >
            <ListRestart />
            Resetar filtro
          </div>
          <div
            onClick={handleSalveFilter}
            className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
          >
            <Star />
            Salvar filtro
          </div>
        </div>
    </div>
  </div>
    // <div className="flex flex-col w-full h-full">
    //   <OptionModal label="Categoria" className="">
    //   {FILTER_HOME_IMOVEIS["Categoria"].values.map((option) => (
    //       <div key={option} className="col-span-6 flex items-center space-x-2">
    //         <Checkbox
    //           id={option}
    //           disabled={isLoading}
    //           className="cursor-pointer"
    //           checked={info.transaction === option}
    //           onCheckedChange={(e) => {
    //             const newValue = e ? option : ''
    //             setInfo((prev) => ({
    //               ...prev,
    //               transaction: newValue,
    //             }))
    //           }}
    //         />
    //         <label
    //           htmlFor={option}
    //           className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    //         >
    //           {option}
    //         </label>
    //       </div>
    //     ))}
    //   </OptionModal>

    //   <OptionModal label="Tipo do imóveis" className="grid-cols-4">
    //     {FILTER_HOME_IMOVEIS["Tipo de imovel"].values.map((option) => (
    //       <div key={option} className="col-span-6 flex items-center space-x-2">
    //         <Checkbox
    //           id={option}
    //           disabled={isLoading}
    //           className="cursor-pointer"
    //           checked={info.types.includes(option)}
    //           onCheckedChange={(isSelected) => {
    //             const updatedTypes = isSelected
    //               ? [...info.types, option] // Adiciona o `option` ao array
    //               : info.types.filter((type) => type !== option); // Remove o `option` do array
    //             setInfo((prev) => ({ ...prev, types: updatedTypes })); // Atualiza o estado com um array válido
    //           }}
    //         />
    //         <label
    //           htmlFor={option}
    //           className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    //         >
    //           {option}
    //         </label>
    //       </div>
    //     ))}
    //   </OptionModal>

    //   <OptionModal label="Detalhes" className="flex mb-1 flex-wrap gap-2">
    //       {FILTER_MODAL_IMOVEIS.combobox.map((f, index) => (
    //         <div
    //           className="flex flex-1 flex-col gap-2"
    //           key={`${f.label}-${index}`}
    //         >
    //           <label className="text-gray-500">{f.label}</label>
    //           <Select
    //             disabled={isLoading}
    //             onValueChange={(e) =>
    //               setInfo((prev) => ({
    //                 ...prev,
    //                 [f.name]: e,
    //               }))
    //             }
    //             defaultValue={info[f.name as Details] || ''}
    //           >
    //             <SelectTrigger className="items-center gap-2 rounded-md border border-[#d0d5dd] px-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
    //               <SelectValue
    //                 placeholder="Quantidade"
    //                 className="text-[12px]"
    //               />
    //             </SelectTrigger>
    //             <SelectContent className="outline-none focus:outline-none">
    //               <SelectGroup>
    //                 {f.values.map((v) => (
    //                   <SelectItem
    //                     key={v}
    //                     value={v}
    //                     className="cursor-pointer hover:bg-gray-400/20 text-[12px]"
    //                   >
    //                     {v}
    //                   </SelectItem>
    //                 ))}
    //               </SelectGroup>
    //             </SelectContent>
    //           </Select>
    //         </div>
    //       ))}
    //   </OptionModal>

    //   {/* Additional OptionModals can follow similar structure */}
    //   <OptionModal label="Área" className="my-1">
    //     <div className="col-span-2 flex flex-wrap justify-around">
    //       <div>
    //         <h1 className="relative">
    //           Area total{' '}
    //           <span className="absolute text-[8px]">
    //             { info.types?.includes('Fazenda / Sitio / Chácara') && '(hectares)'}
    //             { !info.types?.includes('Fazenda / Sitio / Chácara') && '(min²)'}
    //           </span>
    //         </h1>
    //         <div className="flex items-center justify-between gap-4">
    //           <Input
    //             disabled={isLoading}
    //             placeholder="100"
    //             className="max-w-[100px]"
    //             type="number"
    //             value={info.areaTotalMin || ''}
    //             onChange={(e) =>
    //               setInfo((prev) => ({
    //                 ...prev,
    //                 areaTotalMin: Number(Number(e.target.value)),
    //               }))
    //             }
    //           />
    //         </div>
    //       </div>
    //       <div>
    //         <h1 className="relative">
    //           Area total{' '}
    //           <span className="absolute text-[8px]">
    //             { info.types?.includes('Fazenda / Sitio / Chácara') && '(hectares)'}
    //             { !info.types?.includes('Fazenda / Sitio / Chácara') && '(min²)'}
    //           </span>
    //         </h1>
    //         <div className="flex items-center justify-between gap-4">
    //           <Input
    //             disabled={isLoading}
    //             placeholder="100"
    //             type="number"
    //             className="max-w-[100px]"
    //             value={info.areaTotalMax || ''}
    //             onChange={(e) =>
    //               setInfo((prev) => ({
    //                 ...prev,
    //                 areaTotalMax: Number(Number(e.target.value)),
    //               }))
    //             }
    //           />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-span-2 flex flex-wrap justify-around">
    //       <div>
    //         <h1 className="relative">
    //           Area Útil <span className="absolute text-[10px]">(min²)</span>
    //         </h1>
    //         <div className="flex items-center justify-between gap-4">
    //           <Input
    //             disabled={isLoading}
    //             placeholder="100"
    //             type="number"
    //             className="max-w-[100px]"
    //             value={info.areaUtilMin || ''}
    //             onChange={(e) =>
    //               setInfo((prev) => ({
    //                 ...prev,
    //                 areaUtilMin: Number(Number(e.target.value)),
    //               }))
    //             }
    //           />
    //         </div>
    //       </div>
    //       <div>
    //         <h1 className="relative">
    //           Area Útil{' '}
    //           <span className="absolute text-[8px]">
    //             { info.types?.includes('Fazenda / Sitio / Chácara') && '(hectares)'}
    //             { !info.types?.includes('Fazenda / Sitio / Chácara') && '(min²)'}
    //           </span>
    //         </h1>
    //         <div className="flex items-center justify-between gap-4">
    //           <Input
    //             disabled={isLoading}
    //             placeholder="100"
    //             type="number"
    //             className="max-w-[100px]"
    //             value={info.areaUtilMax || ''}
    //             onChange={(e) =>
    //               setInfo((prev) => ({
    //                 ...prev,
    //                 areaUtilMax: Number(Number(e.target.value)),
    //               }))
    //             }
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </OptionModal>
    //   {REGISTER_IMOVEIS_OPTIONS_KEYS.map((key, index: number) => (
    //     <OptionModal label={key} key={`${key}_${index}`} className="flex-wrap">
    //       {REGISTER_IMOVEIS_OPTIONS[key].map((feature: string, index: number) => (
    //         <div
    //           key={index}
    //           className="flex items-center space-x-2 p-1"
    //         >
    //           <Checkbox
    //             disabled={isLoading}
    //             id={feature}
    //             className="cursor-pointer"
    //             checked={info.feature?.includes(feature) || false}
    //             onCheckedChange={(e) => {
    //               const newFeatures = e
    //                 ? [...(info.feature || []), feature]
    //                 : info.feature?.filter((f: string) => f !== feature) || [];
    //               setInfo((prev: any) => ({
    //                 ...prev,
    //                 feature: newFeatures,
    //               }));
    //             }}
    //           />
    //           <label
    //             htmlFor={feature}
    //             className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    //           >
    //             {feature}
    //           </label>
    //         </div>
    //       ))}
    //     </OptionModal>
    //   ))}
    //   <div className="flex flex-col gap-2 p-4">
    //     <div className=" flex items-center justify-between gap-4 ">
    //         <Input
    //           disabled={isLoading}
    //           placeholder="R$10.000.000,00"
    //           value={info.prices[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
    //           type="text"
    //           onChange={(e) => {
    //             const value = Number(e.target.value).replace(/[^0-9.-]+/g, '')
    //             const newPrices = [...info.prices]
    //             newPrices[0] = Number.parseFloat(value) || 0
    //             setInfo((prev) => ({ ...prev, prices: newPrices }))
    //           }}
    //         />

    //       <div className="h-[1px] w-[10rem] bg-[#d9d9d9] max-md:sr-only" />
    //         <Input
    //           disabled={isLoading}
    //           placeholder="R$10.000.000,00"
    //           value={info.prices[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
    //           type="text"
    //           onChange={(e) => {
    //             const value = Number(e.target.value).replace(/[^0-9.-]+/g, '')
    //             const newPrices = [...info.prices]
    //             newPrices[1] = Number.parseFloat(value) || 0
    //             setInfo((prev) => ({ ...prev, prices: newPrices }))
    //           }}
    //         />
    //     </div>
    //     <Slider
    //       value={info.prices}
    //       defaultValue={info.prices}
    //       onValueChange={(e) => setInfo((prev) => ({ ...prev, prices: e }))}
    //       step={2}
    //       max={5000000000}
    //     />
    //   </div>
    //   <div className="flex flex-col gap-4 static">
    //     <Button
    //       className="flex gap-4 py-4"
    //       onClick={() => onSubmit(info)}
    //       disabled={isLoading}
    //     >
    //       {isLoading ? <Loader2 className="animate-spin" /> : <Search size={20} />}
    //       {!isOrder ? "Buscar" : "Encomendar"}
    //     </Button>
    //     <div className="flex flex-wrap items-center justify-between gap-4 max-sm:justify-center">
    //       <div
    //         onClick={handleResetFilter}
    //         className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
    //       >
    //         <ListRestart />
    //         Resetar filtro
    //       </div>
    //       <div
    //         onClick={handleSalveFilter}
    //         className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
    //       >
    //         <Star />
    //         Salvar filtro
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}